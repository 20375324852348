import React, { useEffect, useState } from "react";
// import { works } from "../data/profileData";

const ExpandableCard = ({
  id,
  content,
  imageUrl,
  expanded,
  setExpanded,
  toggleExpansion,
}) => {
  return (
    <div
      className={`card ${
        expanded ? "expanded" : ""
      } bg-stone-200 rounded-lg p-4 m-2 flex flex-col md:max-w-xl md:flex-row`}
      onClick={(e) => toggleExpansion(id, e)}
    >
      <div className="flex justify-center min-w-[25%]">
        <img className="w-18 h-48 object-cover " src={imageUrl} alt="" />
      </div>
      <div className="flex items-center">
        {expanded && (
          <p className=" my-7 mr-5 text-left font-sans font-semibold p-3 text-base leading-6 text-gray-700">
            {content}
          </p>
        )}
      </div>
    </div>
  );
};

const ExpandableCardList = ({ workData }) => {
  // const [worksData, setWorksData] = useState();
  // useEffect(() => {
  //   fetch("https://6485f18aa795d24810b7828b.mockapi.io/works/worksComponent")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setWorksData(data[0]?.cardsData);
  //     });
  // }, []);
  // console.log(worksData);

  const [expanded, setExpanded] = useState([]);

  const toggleExpansion = (id, e) => {
    setExpanded(() => {
      const x = [];
      x[id - 1] = true;

      return x;
    });
  };

  return (
    <div className="card-list flex flex-col md:flex-row justify-evenly bg-stone-200 p-4 ">
      {workData?.map((card, index) => (
        <ExpandableCard
          key={card.id}
          id={card.id}
          content={card.content}
          imageUrl={card.imageUrl}
          expanded={expanded[card.id - 1]}
          setExpanded={setExpanded}
          toggleExpansion={toggleExpansion}
        />
      ))}
    </div>
  );
};

// const App = () => {
//   return <ExpandableCardList />;
// };

// export default App;

export default ExpandableCardList;
