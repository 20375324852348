import React from "react";
import { CiLight, CiDark } from "react-icons/ci";

export default function ToggleButton({ toggle, setToggle }) {
  const handleToggleButton = () => {
    setToggle((props) => !props);
  };
  return (
    <>
      {toggle ? (
        <div className="bg-black p-4 flex flex-row-reverse">
          <CiLight onClick={handleToggleButton} size={30} color="white" />
        </div>
      ) : (
        <div className="bg-stone-200 p-4 flex flex-row-reverse">
          <CiDark onClick={handleToggleButton} size={30} />
        </div>
      )}
    </>
  );
}
