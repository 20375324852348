import React from "react";

import LandingPage from "./LandingPage";

function App() {
  return (
    <>
      <LandingPage />
      
    </>
  );
}

export default App;
