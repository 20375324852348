import React, { useState, useEffect } from "react";
import { IS_DARK_THEME } from "./constants/Index";
import Skill from "./components/Skill";
import BannerDark from "./components/BannerDark";
import AboutDark from "./components/AboutDark";
import Works from "./components/Works";
import AboutLight from "./components/AboutLight";
import BannerLight from "./components/BannerLight";
import Testimonial from "./components/Testimonial";
import ToggleButton from "./components/ToggleButton";

function LandingPage() {
  const [toggle, setToggle] = useState(IS_DARK_THEME);
  const [profileIntroData, setProfileIntroData] = useState();

  useEffect(() => {
    fetch(`https://profile-service-hazel.vercel.app/profile/intro/1`, {
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProfileIntroData(data[0]);
      });
  }, []);

  const bannerData = {
    greeting: profileIntroData?.greeting,
    fullName: profileIntroData?.fullName,
    profileSkills: profileIntroData?.profileSkills,
  };
  return (
    <>
      <ToggleButton toggle={toggle} setToggle={setToggle} />
      {toggle ? (
        <BannerDark bannerData={bannerData} />
      ) : (
        <BannerLight bannerData={bannerData} />
      )}
      <Skill skillsData={profileIntroData?.skillSet[0]} />
      {toggle ? (
        <AboutDark briefStory={profileIntroData?.briefStory} />
      ) : (
        <AboutLight briefStory={profileIntroData?.briefStory} />
      )}
      <Works workData={profileIntroData?.works} />

      <Testimonial testimonialData={profileIntroData?.testimonialData} />
    </>
  );
}

export default LandingPage;
