// import React, { useEffect, useState } from "react";
import "./Testimonial.css";

export const Testimony = ({ id, name, image, designation, comment }) => {
  return (
    <>
      <div className="testimony">
        <img className="testimony__image" src={image} alt="Testimonial" />
        <div className="testimony__content">
          <p className="testimony__text">{comment}</p>
          <p className="testimony_designation">
            <span className="testimony__author">{name}</span>, {designation}
          </p>
        </div>
      </div>
    </>
  );
};

export default function Testimonial({ testimonialData }) {
  // const [testimonialData, setTestimonialData] = useState([]);

  // useEffect(() => {
  //   fetch(`https://profile-service-hazel.vercel.app/profile/intro/1`, {
  //     mode: "cors",
  //     headers: {
  //       "Content-Type": "application/json"
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setTestimonialData(data[0].testimonialData);
  //     });
  // }, []);

  const testimony =
    testimonialData &&
    testimonialData.length &&
    testimonialData.map((e) => {
      return (
        <>
          <Testimony
            key={e.id}
            id={e.id}
            name={e.name}
            image={e.image}
            designation={e.designation}
            comment={e.comment}
          />
        </>
      );
    });

  return (
    <>
      <div className="testimonials">{testimony}</div>
    </>
  );
}
