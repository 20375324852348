// import React, { useEffect, useState } from "react";
// import Typed from "react-typed";
// import { profile } from "../data/profileData";

import photolight from "../assets/img/img.png";
import Typewriter from "typewriter-effect";

<Typewriter
  options={{
    strings: ["Hello", "World"],
    autoStart: true,
    loop: true,
  }}
/>;

export default function Banner({ bannerData }) {
  // const [introData, setIntroData] = useState();
  // useEffect(() => {
  //   fetch("https://648564a1a795d24810b6eaa1.mockapi.io/api/profile/intro")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log("inside fetch", data);
  //       setIntroData(data[0]);
  //     });
  // }, []);

  // // console.log("outside fetch", introData);
  // // const { greeting, fullName, profileSkills } = introData;
  // // const greeting = introData?.greeting;
  // // const profileSkills = introData?.profileSkills;
  // // const fullName = introData?.fullName;
  // const handleUpdateUser = () => {
  //   fetch("https://648564a1a795d24810b6eaa1.mockapi.io/api/profile/intro/1", {
  //     method: "put",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       userId: 1,
  //       fullName: "Kumar Shubham",
  //       greeting: "Hello There!",
  //       profileSkills: [
  //         "Software Engineer",
  //         "Full-Stack Developer",
  //         "Node Developer"
  //       ],
  //       briefStory:
  //         "I started my journey as software developer in 2015. My first project was creating a website using Wordpress CMS .In early years of my experience I worked on various aspect of digital technologies. I am sharing my journey as developer on github with my codes and on twitter in words.",
  //       id: "1"
  //     })
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data) {
  //         // console.log("parsed respo", data);
  //       }
  //       if (data.fullName === "Shubh Mallik") {
  //         alert("new user created successfully");
  //       } else {
  //         alert("something went wrong");
  //       }
  //     });
  // };

  return (
    <div className="bg-stone-200 grid sm:grid-cols-1 md:grid-cols-2 ">
      <div className="md:pt-45rem md:pl-50rem md:mt-12 sm:mx-auto">
        <img
          className="max-h-screen mx-auto"
          src={photolight}
          alt="portfolio pic"
        />
      </div>
      <div className="py-12 mx-[100px] mt-[100px] lg:mt-[180px] md:mx-0 font-sans font-semibold">
        <div className="text-gray-900 tracking-tighter leading-1 p-[10px] text-3xl md:text-3xl xl:text-5xl md:p-[20px]">
          {bannerData?.greeting}
        </div>
        <div className="text-gray-700 tracking-tighter leading-1 p-[10px] text-4xl md:text-5xl xl:text-6xl md:p-[20px]">
          I am{" "}
          <span className="text-gray-900 font-bold">
            {bannerData?.fullName}
          </span>
        </div>
        <div className="text-gray-700 tracking-tighter leading-1 p-[10px] text-2xl md:text-4xl xl:text-4xl md:p-[20px]">
          <span className="flex">
            A{" "}
            <span className="text-gray-900 mx-2">
              <Typewriter
                options={{
                  strings: bannerData?.profileSkills,
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </span>
        </div>
        {/* {false && (
          <div className="p-5">
            <button
              onClick={() => handleUpdateUser()}
              type="button"
              className="p-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 bg-gradient-to-r from-sky-300/75 to-sky-300/25 hover:from-sky-400/100 hover:to-sky-400/50">
              Update My Data
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
}
