import React from "react";
import group from "../assets/img/group.jpg";

export default function About({ briefStory }) {
  return (
    <div className=" bg-zinc-950 flex flex-col items-center md:flex-row">
      <div className="flex-1">
        <img src={group} alt="team work" />
      </div>
      <p className=" text-gray-300 p-10 text-lg flex-1 leading-8 font-sans font-semibold ">
        {briefStory}
      </p>
    </div>
  );
}
