// import React, { useEffect, useState } from "react";
// import Typed from "react-typed";
import photoDark from "../assets/img/img.png";
import Typewriter from "typewriter-effect";

export default function Banner({ bannerData }) {
  // const [introData, setIntroData] = useState();
  // useEffect(() => {
  //   fetch("https://648564a1a795d24810b6eaa1.mockapi.io/api/profile/intro")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log("inside fetch", data);
  //       setIntroData(data[0]);
  //     });
  // }, []);

  // console.log("outside fetch", introData);

  // const handleAddUser = () => {
  //   fetch("https://648564a1a795d24810b6eaa1.mockapi.io/api/profile/intro", {
  //     method: "post",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       userId: 100,
  //       fullName: "Akanksha",
  //       greeting: "Welcome everyone",
  //       profileSkills: ["Product Manager"],
  //       briefStory: "briefStory 2",
  //       id: "3",
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data) {
  //         // console.log("parsed respo", data);
  //       }
  //       if (data.fullName === "Akanksha") {
  //         alert("new user created successfully");
  //       } else {
  //         alert("something went wrong");
  //       }
  //     });
  // };

  return (
    <div className="bg-black grid sm:grid-cols-1 md:grid-cols-2 ">
      <div className="md:pt-45rem md:pl-50rem md:mt-12 sm:mx-auto">
        <img
          className="max-h-screen mx-auto"
          src={photoDark}
          alt="portfolio pic"
        />
      </div>
      <div className="py-12 mx-[100px] mt-[100px] lg:mt-[180px] md:mx-0 font-sans font-semibold">
        <div className="text-sky-300 tracking-tighter leading-1 p-[10px] text-3xl md:text-3xl xl:text-5xl md:p-[20px]">
          {bannerData?.greeting}
        </div>
        <div className="text-sky-300/100 tracking-tighter leading-1 p-[10px] text-4xl md:text-5xl xl:text-6xl md:p-[20px]">
          I am{" "}
          <span className="text-sky-300/75 font-bold">
            {bannerData?.fullName}
          </span>
        </div>
        <div className="text-sky-300/50 tracking-tighter leading-1 p-[10px] text-2xl md:text-4xl xl:text-4xl md:p-[20px]">
          <span className="flex">
            A{" "}
            <span className="text-sky-300/50 mx-2">
              <Typewriter
                options={{
                  strings: bannerData?.profileSkills,
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </span>
        </div>
        {/* {false && (
          <div className="p-3">
            <button
              onClick={() => handleAddUser()}
              type="button"
              className="p-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 bg-gradient-to-r from-sky-300/75 to-sky-300/25 hover:from-sky-400/100 hover:to-sky-400/50"
            >
              Add More User
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
}
