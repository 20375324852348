import React, { useState } from "react";
import { SiReact, SiNodedotjs, SiExpress } from "react-icons/si";
import { TbBrandNextjs } from "react-icons/tb";
// import { skillset } from "../data/profileData";
const Skill = ({ skillsData }) => {
  const [selectedSkill, setSelectedSkill] = useState();
  // const [skillSet, setSkillSet] = useState();

  // useEffect(() => {
  //   fetch("https://648564a1a795d24810b6eaa1.mockapi.io/api/profile/skillSet")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setSkillSet(data[0]);
  //       setSkill(data[0].summary);
  //       // console.log(data[0].skills);
  //     });
  // }, []);

  // function showSkill(discription) {
  //   setSkill((previous) => {
  //     if (previous !== discription) {
  //       return discription;
  //     }
  //     return discription;
  //   });
  // }
  const handleClick = (descrip) => {
    setSelectedSkill(descrip);
  };
  const SkillComponent = skillsData?.skills?.map((e) => {
    return (
      <div
        key={e.id}
        className="flex flex-row justify-center space-x-4 rounded-lg hover:bg-slate-100 hover:text-lg hover:text-gray-500"
        onClick={() => handleClick(e.discription)}
      >
        {e.icon === "SiReact" && <SiReact size={40} />}
        {e.icon === "SiNodedotjs" && <SiNodedotjs size={40} />}
        {e.icon === "SiExpress" && <SiExpress size={40} />}
        {e.icon === "TbBrandNextjs" && <TbBrandNextjs size={40} />}
        <button>{e.title}</button>
      </div>
    );
  });
  return (
    <div className="flex flex-col space-y-4 min-h-[50%] md:flex-row space-x-4 p-10 ">
      <div className="flex flex-col basis-3/12 space-y-10 justify-center text-slate-800 text-xl overflow-auto">
        {SkillComponent}
      </div>
      <div className="font-sans font-semibold text-lg leading-normal p-3 flex items-center text-gray-700 lg:max-w-lg md:text-left md:py-4 sm:text-center">
        <p>{selectedSkill || skillsData?.summary}</p>
      </div>
    </div>
  );
};

export default Skill;
