// import React, { useEffect, useState } from "react";
import group from "../assets/img/group.jpg";

export default function About({ briefStory }) {
  // const [introData, setIntroData] = useState();
  // useEffect(() => {
  //   fetch("https://648564a1a795d24810b6eaa1.mockapi.io/api/profile/intro")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log("inside fetch", data);
  //       setIntroData(data[0]);
  //     });
  // }, []);
  // console.log("outside fetch", introData);

  return (
    <div className=" bg-stone-500 flex flex-col md:flex-row  items-center">
      <div className="flex-1">
        <img src={group} alt="team work" />
      </div>

      <p className=" text-stone-200 p-10 text-lg flex-1 leading-8 font-sans font-semibold ">
        {briefStory}
      </p>
    </div>
  );
}
